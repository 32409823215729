/**
 * Ragib Un Nur
 * ragib@nerddevs.com
 * Created on Thu Jul 14 2022
 */

<template>
  <div class="dropdown relative inline-block">
    <div
      class="mr-4 mt-4 flex items-center lg:mr-10"
    >
      <div
        v-if="showName"
        class="top-20 right-5 hidden lg:absolute lg:z-10 lg:block lg:min-w-max lg:rounded lg:bg-blue-500 lg:px-3
          lg:py-2 lg:font-normal lg:text-white"
      >
        {{ name }}
      </div>
      <div class="relative">
        <button
          class="h-11 w-11 rounded-full"
          @mouseover="showName = !showName"
          @focus="showName = !showName"
          @mouseout="showName = !showName"
          @blur="showName = !showName"
          @click="isMemberMenuOpen = !isMemberMenuOpen"
        >
          <ImageAvatar
            v-if="user"
          />
        </button>
        <button
          v-if="isMemberMenuOpen"
          tabindex="-1"
          class="fixed inset-0 top-0 z-50 h-full w-full cursor-default bg-gray-500 opacity-20"
          @click="isMemberMenuOpen = false"
        />
        <div
          v-if="isMemberMenuOpen"
          class="absolute -right-5 z-50 mt-2 max-w-[240px]
            overflow-hidden rounded-lg bg-white shadow-sm shadow-black xs:max-w-fit"
        >
          <ul
            tabindex="0"
            class="menu w-96 max-w-[90vw] rounded p-2 shadow shadow-gray-700
              lg:w-[12vw]"
          >
            <span
              v-if="user"
              class="flex p-3 hover:bg-white lg:hidden"
            >
              <ImageAvatar />
              <div
                class="text-normal ml-3 flex-col items-baseline truncate
                p-1 font-medium hover:bg-white xs:text-xl"
              >
                {{ name }}
                <p
                  class="truncate text-xs font-normal text-gray-600
                  xs:text-base"
                >
                  {{ user.username }}
                </p>
              </div>
            </span>
            <li
              class="rounded hover:delay-100"
            >
              <HeaderTextLinkButton
                name="Sign out"
                block
                @click="logout"
              />
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import {
  computed,
  ref,
} from 'vue';
import {
  useRouter,
} from 'vue-router';
import ImageAvatar from '@/components/Helper/ImageAvatar.vue';
import HeaderTextLinkButton from '@/components/NavBar/HeaderTextLinkButton.vue';
import {
  useStore,
} from '@/store';

const router = useRouter();
const authStore = useStore.auth;

defineProps({
  congested: {
    type: Boolean,
    default: false,
  },
});

const showName = ref(false);
const isMemberMenuOpen = ref(false);

const user = computed(() => authStore.user);
const name = computed(() => {
  if (!user.value) {
    return '';
  }
  return 'A';
});

async function logout(): Promise<void> {
  authStore.logout();
  if (router.currentRoute.value.path !== '/') {
    router.push('/');
  }
}

</script>
