/*
 * Ankur Mursalin
 *
 * https://encryptioner.github.io/
 *
 * Created on Thu Aug 04 2022
 */

import {
  defineStore,
} from 'pinia';
import {
  identifyDeviceType,
  viewportBreakpoint,
} from '@/helpers';
import {
  useAuthStore,
} from '@/store/auth';

// eslint-disable-next-line import/prefer-default-export
export const useAppStore = defineStore('app', {
  state: () => ({
    device: {
      isMobile: false,
      // iPad pro will not be identified as mobile device,
      // but it is a touch device
      isTouchDevice: false,
      isSafari: false,
      isChrome: false,
      isFirefox: false,
      isMac: false,
      isWindows: false,
      isLinux: false,
      isIPad: false,
      isIPhone: false,
      isAndroidTablet: false,
      isAndroid: false,
    },
    breakpoint: '',
    isBootstrapped: false,
    prevRoutePath: '',
    sidebars: {
      messaging: {
        show: false,
      },
    },
    dialogs: {
      confirm: {
        show: false,
      },
    },
  }),
  getters: {
    user: () => useAuthStore().user,
  },
  actions: {
    async init(prevRoutePath: string) {
      this.prevRoutePath = prevRoutePath;
      if (this.isBootstrapped) {
        return;
      }

      await useAuthStore().localLogin();

      const device = identifyDeviceType();
      this.breakpoint = viewportBreakpoint();
      this.device = device;
      this.isBootstrapped = true;
    },
  },
  persist: {
    storage: window.sessionStorage,
  },
});
