import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createElementVNode as _createElementVNode, createVNode as _createVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "dropdown relative inline-block" }
const _hoisted_2 = { class: "mr-4 mt-4 flex items-center lg:mr-10" }
const _hoisted_3 = {
  key: 0,
  class: "top-20 right-5 hidden lg:absolute lg:z-10 lg:block lg:min-w-max lg:rounded lg:bg-blue-500 lg:px-3 lg:py-2 lg:font-normal lg:text-white"
}
const _hoisted_4 = { class: "relative" }
const _hoisted_5 = {
  key: 1,
  class: "absolute -right-5 z-50 mt-2 max-w-[240px] overflow-hidden rounded-lg bg-white shadow-sm shadow-black xs:max-w-fit"
}
const _hoisted_6 = {
  tabindex: "0",
  class: "menu w-96 max-w-[90vw] rounded p-2 shadow shadow-gray-700 lg:w-[12vw]"
}
const _hoisted_7 = {
  key: 0,
  class: "flex p-3 hover:bg-white lg:hidden"
}
const _hoisted_8 = { class: "text-normal ml-3 flex-col items-baseline truncate p-1 font-medium hover:bg-white xs:text-xl" }
const _hoisted_9 = { class: "truncate text-xs font-normal text-gray-600 xs:text-base" }
const _hoisted_10 = { class: "rounded hover:delay-100" }

import {
  computed,
  ref,
} from 'vue';
import {
  useRouter,
} from 'vue-router';
import ImageAvatar from '@/components/Helper/ImageAvatar.vue';
import HeaderTextLinkButton from '@/components/NavBar/HeaderTextLinkButton.vue';
import {
  useStore,
} from '@/store';


export default /*@__PURE__*/_defineComponent({
  __name: 'MemberMenu',
  props: {
  congested: {
    type: Boolean,
    default: false,
  },
},
  setup(__props) {

const router = useRouter();
const authStore = useStore.auth;



const showName = ref(false);
const isMemberMenuOpen = ref(false);

const user = computed(() => authStore.user);
const name = computed(() => {
  if (!user.value) {
    return '';
  }
  return 'A';
});

async function logout(): Promise<void> {
  authStore.logout();
  if (router.currentRoute.value.path !== '/') {
    router.push('/');
  }
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (showName.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(name.value), 1))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("button", {
          class: "h-11 w-11 rounded-full",
          onMouseover: _cache[0] || (_cache[0] = ($event: any) => (showName.value = !showName.value)),
          onFocus: _cache[1] || (_cache[1] = ($event: any) => (showName.value = !showName.value)),
          onMouseout: _cache[2] || (_cache[2] = ($event: any) => (showName.value = !showName.value)),
          onBlur: _cache[3] || (_cache[3] = ($event: any) => (showName.value = !showName.value)),
          onClick: _cache[4] || (_cache[4] = ($event: any) => (isMemberMenuOpen.value = !isMemberMenuOpen.value))
        }, [
          (user.value)
            ? (_openBlock(), _createBlock(ImageAvatar, { key: 0 }))
            : _createCommentVNode("", true)
        ], 32),
        (isMemberMenuOpen.value)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              tabindex: "-1",
              class: "fixed inset-0 top-0 z-50 h-full w-full cursor-default bg-gray-500 opacity-20",
              onClick: _cache[5] || (_cache[5] = ($event: any) => (isMemberMenuOpen.value = false))
            }))
          : _createCommentVNode("", true),
        (isMemberMenuOpen.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createElementVNode("ul", _hoisted_6, [
                (user.value)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_7, [
                      _createVNode(ImageAvatar),
                      _createElementVNode("div", _hoisted_8, [
                        _createTextVNode(_toDisplayString(name.value) + " ", 1),
                        _createElementVNode("p", _hoisted_9, _toDisplayString(user.value.username), 1)
                      ])
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("li", _hoisted_10, [
                  _createVNode(HeaderTextLinkButton, {
                    name: "Sign out",
                    block: "",
                    onClick: logout
                  })
                ])
              ])
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}
}

})