import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex min-h-[60px] w-96 justify-between gap-1" }
const _hoisted_2 = { class: "flex" }
const _hoisted_3 = { class: "flex grow justify-center" }
const _hoisted_4 = { class: "flex max-h-28 items-center" }
const _hoisted_5 = { class: "flex items-center text-center" }

import {
  CheckCircleIcon,
  XMarkIcon,
} from '@heroicons/vue/24/outline';
import {
  PhInfo,
  PhWarning,
} from '@phosphor-icons/vue';
import {
  NOTIFICATION,
} from '@/bus';


export default /*@__PURE__*/_defineComponent({
  __name: 'PropNotification',
  props: {
  notification: {
    type: String,
    required: true,
  },
  notificationType: {
    type: String,
    required: true,
  },
  maxWidth: {
    type: String,
    default: '',
  },
},
  emits: ["close"],
  setup(__props) {







return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "fixed inset-x-0 top-24 z-40 mx-auto max-h-32 w-fit overflow-hidden rounded-lg bg-white px-3 py-2 shadow-xl shadow-gray-400",
    style: _normalizeStyle({ maxWidth: __props.maxWidth })
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", {
          class: _normalizeClass(["max-h-28 w-2 rounded-full", {
            'bg-amber-500': __props.notificationType === _unref(NOTIFICATION).INFO,
            'bg-emerald-500': __props.notificationType === _unref(NOTIFICATION).SUCCESS,
            'bg-red-600': __props.notificationType === _unref(NOTIFICATION).ERROR,
          }])
        }, null, 2)
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("span", _hoisted_4, [
          (__props.notificationType === _unref(NOTIFICATION).INFO)
            ? (_openBlock(), _createBlock(_unref(PhInfo), {
                key: 0,
                class: "size-8 text-amber-500"
              }))
            : (__props.notificationType === _unref(NOTIFICATION).SUCCESS)
              ? (_openBlock(), _createBlock(_unref(CheckCircleIcon), {
                  key: 1,
                  class: "size-8 text-emerald-500"
                }))
              : (__props.notificationType === _unref(NOTIFICATION).ERROR)
                ? (_openBlock(), _createBlock(_unref(PhWarning), {
                    key: 2,
                    class: "size-8 text-red-600"
                  }))
                : _createCommentVNode("", true)
        ]),
        _createElementVNode("span", _hoisted_5, _toDisplayString(__props.notification), 1)
      ]),
      _createElementVNode("button", {
        type: "button",
        class: "flex max-h-28 items-center rounded-lg text-gray-400 hover:text-red-400",
        "aria-label": "Close",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
      }, [
        _createVNode(_unref(XMarkIcon), { class: "size-6" })
      ])
    ])
  ], 4))
}
}

})