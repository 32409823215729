/**
 * Ragib Un Nur
 * ragib@nerddevs.com
 * Created on Fri Jul 08 2022
 */

<template>
  <button
    class="p-0 text-left"
    @click="$emit( event || 'click')"
  >
    <RouterLink
      class="w-full rounded-lg p-3 font-normal hover:bg-red-400 hover:text-white hover:no-underline"
      :target="url ? '_blank' : undefined"
      :href="url"
      :to="route"
      :class="{'bg-red-200 text-red-500 font-bold w-full': name === router.currentRoute.value.name}"
    >
      {{ name }}
    </RouterLink>
  </button>
</template>

<script lang="ts" setup>
import {
  useRouter,
} from 'vue-router';

export interface IHeaderTextLink {
  name: string;
  route?: string;
  url?: string;
  event?: string;
  userOnly?: boolean;
  hidden?: boolean;
  isMobile?: boolean;
}

defineProps({
  name: {
    type: String,
    required: true,
  },
  route: {
    type: String,
    required: false,
    default: '',
  },
  url: {
    type: String,
    required: false,
    default: '',
  },
  block: {
    type: Boolean,
    required: false,
  },
  event: {
    type: String,
    default: '',
  },
});

const router = useRouter();
</script>
