<template>
  <button
    ref="buttonRef"
    class="mx-auto flex h-13 w-full items-center justify-center gap-1 rounded-lg px-2
    text-center font-medium leading-[48px] transition-all duration-300 hover:transition-all hover:duration-300"
    :type="type"
    :class=" {
      'category-1-attr': category === 'category-1' && !disabled,
      'category-2-attr': category === 'category-2' && !disabled,
      'category-3-attr': category === 'category-3' && !disabled,
      'category-4-attr': category === 'category-4' && !disabled,
      'category-5-attr': category === 'category-5' && !disabled,

      'shadow-md': category !== 'category-3',

      'text-[14px] sm:text-[16px] lg:text-[20px]': largeText,
      'text-[12px] sm:text-[14px] lg:text-[16px]': !largeText,

      'active-animation': !disabled,
      'bg-neutral-1/10 text-neutral-1/60': disabled,
    } "
    :disabled="disabled"
  >
    <slot />
    <template
      v-if="content"
    >
      {{ content }}
    </template>
  </button>
</template>

<script lang="ts" setup>
import {
  PropType,
  ref,
} from 'vue';

defineProps({
  content: {
    type: String,
    default: '',
  },
  type: {
    type: String as PropType<typeof types[number]>,
    default: 'button',
    validator: (type: typeof types[number]) => types.includes(type),
  },
  largeText: {
    type: Boolean,
    default: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  // category-1 is the blue button, category-2 is light blue button, category-3 is the cancel button, category-4 is the red button
  category: {
    type: String as PropType<typeof categories[number]>,
    default: 'category-1',
    validator: (category: typeof categories[number]) => categories.includes(category),
  },
});

const buttonRef = ref<HTMLButtonElement | null>(null);

defineExpose({
  buttonRef,
});

</script>

<script lang="ts">

const categories = ['category-1', 'category-2', 'category-3', 'category-4', 'category-5'] as const;
const types = ['button', 'submit', 'reset'] as const;

</script>

<style scoped lang="scss">

.category-1-attr {
  @apply border-2 border-blue-6 bg-blue-6 text-white hover:bg-blue-7 hover:border-blue-7
  shadow-neutral-1/40 focus:border-neutral-1 shadow-sm;
}

.category-2-attr {
  @apply border border-blue-9 bg-blue-9 text-neutral-1 hover:border-neutral-1;
}

.category-3-attr {
  @apply border border-[#F7F7F7] bg-[#F7F7F7] px-8 text-neutral-1 hover:border-neutral-1;
}

.category-4-attr {
  @apply bg-red-600 text-white hover:bg-red-700;
}

.category-5-attr {
  @apply bg-[#F4F5F7] hover:bg-white text-gray-700 border-neutral-1/50 border-1 hover:border-neutral-1;
}

.active-animation:active:hover,
.active-animation:active:focus {
  animation: button-pop 0s ease-out;
  transform: scale(0.97);
}

.active-animation:active {
  animation: button-pop 0.25s ease-out;
}

@keyframes button-pop {
  0% {
    transform: scale(.90)
  }
  40% {
    transform: scale(1.02)
  }
  to {
    transform: scale(1)
  }
}
</style>
