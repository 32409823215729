import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, resolveComponent as _resolveComponent, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "relative min-h-screen flex-col" }

import {
  computed,
  onBeforeMount,
  onBeforeUnmount,
} from 'vue';
import {
  useRoute,
} from 'vue-router';
import {
  viewportBreakpoint,
} from './helpers';
import BusNotification from '@/components/App/BusNotification.vue';
import FooterComponent from '@/components/App/FooterComponent.vue';
import HeaderComponent from '@/components/NavBar/HeaderComponent.vue';
import {
  useStore,
} from '@/store';


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {


let resizedBreakpointEventHandler: (e: Event) => void;

const route = useRoute();
const appStore = useStore.app;

const showDefaultHeader = computed(() => {
  const { fullPath } = route;
  return !fullPath.startsWith('/auth')
    && !fullPath.startsWith('/admin');
});

const showDefaultFooter = computed(() => {
  const { fullPath } = route;
  return showDefaultHeader.value
    && !fullPath.startsWith('/user');
});

onBeforeMount(() => {
  resizedBreakpointEventHandler = (): void => {
    const resizedBreakpoint = viewportBreakpoint();
    if (resizedBreakpoint !== appStore.breakpoint) {
      appStore.breakpoint = resizedBreakpoint;
    }
  };
  window.addEventListener('resize', resizedBreakpointEventHandler);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', resizedBreakpointEventHandler);
});

return (_ctx: any,_cache: any) => {
  const _component_RouterView = _resolveComponent("RouterView")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (showDefaultHeader.value)
      ? (_openBlock(), _createBlock(HeaderComponent, { key: 0 }))
      : _createCommentVNode("", true),
    _createElementVNode("main", null, [
      _createVNode(BusNotification),
      _createVNode(_component_RouterView)
    ]),
    (showDefaultFooter.value)
      ? (_openBlock(), _createBlock(FooterComponent, { key: 1 }))
      : _createCommentVNode("", true)
  ]))
}
}

})