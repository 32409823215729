import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import {
  onBeforeMount, ref,
} from 'vue';
import Bus, {
  NOTIFICATION,
} from '@/bus';
import PropNotification from '@/components/App/PropNotification.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'BusNotification',
  setup(__props) {


const notification = ref('');
const notificationType = ref('');
const showNotification = ref(false);

function _hideNonPermanentNotification(isPermanent?: boolean): void {
  if (isPermanent) {
    return;
  }
  window.setTimeout(() => {
    showNotification.value = false;
  }, 5000);
}

function _handleSuccessNotification(): void {
  Bus.on(NOTIFICATION.SUCCESS, (e) => {
    notification.value = e.message;
    notificationType.value = NOTIFICATION.SUCCESS;
    showNotification.value = true;
    _hideNonPermanentNotification(e.permanent);
  });
}

function _handleErrorNotification(): void {
  Bus.on(NOTIFICATION.ERROR, (e) => {
    notification.value = e.message;
    notificationType.value = NOTIFICATION.ERROR;
    showNotification.value = true;
    _hideNonPermanentNotification(e.permanent);
  });
}

function _handleInfoNotification(): void {
  Bus.on(NOTIFICATION.INFO, (e) => {
    notification.value = e.message;
    notificationType.value = NOTIFICATION.INFO;
    showNotification.value = true;
    _hideNonPermanentNotification(e.permanent);
  });
}

onBeforeMount(() => {
  _handleSuccessNotification();
  _handleErrorNotification();
  _handleInfoNotification();
});


return (_ctx: any,_cache: any) => {
  return (showNotification.value)
    ? (_openBlock(), _createBlock(PropNotification, {
        key: 0,
        notification: notification.value,
        notificationType: notificationType.value,
        onClose: _cache[0] || (_cache[0] = ($event: any) => (showNotification.value = false))
      }, null, 8, ["notification", "notificationType"]))
    : _createCommentVNode("", true)
}
}

})