import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "z-50" }
const _hoisted_2 = { class: "flex justify-between" }
const _hoisted_3 = { class: "flex" }
const _hoisted_4 = ["src"]
const _hoisted_5 = {
  key: 0,
  class: "absolute left-4 z-20 mt-4 lg:hidden"
}
const _hoisted_6 = { tabindex: "0" }
const _hoisted_7 = {
  key: 0,
  class: "z-50 h-7 w-7 text-[#ff0000]"
}
const _hoisted_8 = {
  key: 1,
  class: "absolute top-auto left-0 z-50 w-48 rounded-lg border-gray-900 bg-white p-2 py-2 shadow-sm shadow-black"
}
const _hoisted_9 = ["src"]
const _hoisted_10 = { class: "mr-4 flex" }

import {
  Bars3Icon,
  XMarkIcon,
} from '@heroicons/vue/24/outline';
import {
  computed, ref,
} from 'vue';
import {
  useRouter,
} from 'vue-router';
import MemberMenu from '@/components/NavBar/MemberMenu.vue';
import {
  useStore,
} from '@/store';


export default /*@__PURE__*/_defineComponent({
  __name: 'HeaderComponent',
  setup(__props) {

const router = useRouter();

const authStore = useStore.auth;

const isLeftBurgerMenuOpen = ref(false);

const user = computed(() => authStore.user);


return (_ctx: any,_cache: any) => {
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_RouterLink, { to: "/" }, {
          default: _withCtx(() => [
            _createElementVNode("img", {
              src: require('@/assets/site-assets/logo.png'),
              alt: "logo",
              class: "mx-auto mt-5 h-36 w-36 rounded"
            }, null, 8, _hoisted_4)
          ]),
          _: 1
        })
      ]),
      (user.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createElementVNode("button", {
              class: "flex h-9 w-9 items-center justify-center rounded-full hover:bg-gray-400",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (isLeftBurgerMenuOpen.value = !isLeftBurgerMenuOpen.value))
            }, [
              _createElementVNode("label", _hoisted_6, [
                (!isLeftBurgerMenuOpen.value)
                  ? (_openBlock(), _createBlock(_unref(Bars3Icon), {
                      key: 0,
                      class: "h-6 w-6 hover:cursor-pointer"
                    }))
                  : _createCommentVNode("", true)
              ]),
              (isLeftBurgerMenuOpen.value)
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                    _createVNode(_unref(XMarkIcon))
                  ]))
                : _createCommentVNode("", true)
            ]),
            (isLeftBurgerMenuOpen.value)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 0,
                  tabindex: "-1",
                  class: "fixed inset-0 top-0 z-40 h-full w-full cursor-default bg-gray-500 opacity-20",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (isLeftBurgerMenuOpen.value = false))
                }))
              : _createCommentVNode("", true),
            (isLeftBurgerMenuOpen.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_8))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      _createVNode(_component_RouterLink, { to: "/" }, {
        default: _withCtx(() => [
          _createElementVNode("img", {
            src: require('@/assets/site-assets/logo.png'),
            alt: "logo",
            class: _normalizeClass(["absolute", {'left-6 top-4 w-11 lg:hidden': !user.value,
                   'left-[calc(50vw-calc(2.5rem/2))] top-2 mt-2 w-10 lg:hidden': user.value}])
          }, null, 10, _hoisted_9)
        ]),
        _: 1
      }),
      _createElementVNode("div", _hoisted_10, [
        (user.value)
          ? (_openBlock(), _createBlock(MemberMenu, {
              key: 0,
              congested: true
            }))
          : _createCommentVNode("", true)
      ]),
      (!user.value)
        ? (_openBlock(), _createElementBlock("button", {
            key: 1,
            class: "mt-3 mr-5 h-fit w-fit rounded-md bg-red-500 py-2 px-3 text-sm text-white shadow-sm shadow-purple-400 hover:bg-red-600 hover:shadow-none sm:mr-9 sm:text-base sm:font-semibold",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_unref(router).push('/auth/login')))
          }, " Sign in "))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})