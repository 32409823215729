import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "absolute bottom-0 z-10 border-t-1 border-white" }
const _hoisted_2 = { class: "p-4 text-center text-sm sm:text-base" }

import {
  CONFIG,
} from '@/helpers';


export default /*@__PURE__*/_defineComponent({
  __name: 'FooterComponent',
  setup(__props) {


const currentYear = new Date().getFullYear();


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("footer", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "w-screen bg-red-500 p-3 py-4 lg:flex lg:items-center lg:justify-between" }, [
      _createElementVNode("div", { class: "flex items-center justify-center" })
    ], -1)),
    _createElementVNode("p", _hoisted_2, " Copyright © " + _toDisplayString(_unref(currentYear)) + " " + _toDisplayString(_unref(CONFIG).appName) + ", all rights reserved ", 1)
  ]))
}
}

})