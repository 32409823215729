/* eslint-disable import/order */
import {
  RouteRecordRaw,
  createRouter,
  createWebHistory,
} from 'vue-router';
import {
  CONFIG,
  CONSTANTS,
  redirectUserAfterLogin,
} from '@/helpers';
import {
  useStore,
} from '@/store';

// Auth
import AuthView from '@/views/Auth/AuthView.vue';
import LoginView from '@/views/Auth/LoginView.vue';

// Admin
import AdminTemplate from '@/views/Admin/AdminTemplate.vue';

const isGuest = {
  meta: {
    isGuest: true,
  },
};

const routes: Array<RouteRecordRaw> = [
  {
    path: '/auth',
    name: 'Auth',
    component: AuthView,
    children: [
      {
        path: 'login',
        name: 'Login',
        component: LoginView,
        meta: {
          title: 'Login',
          ...isGuest.meta,
        },
      },
    ],
  },
  {
    path: '/admin',
    name: 'AdminTemplate',
    component: AdminTemplate,
    children: [
      {
        path: 'user-list',
        name: 'UserList',
        component: () => import(/* webpackChunkName: "admin-user-list" */ '../views/Admin/UserList.vue'),
        meta: {
          title: 'User List',
        },
      },
      {
        path: 'push-notification-list',
        name: 'PushNotificationList',
        component: () => import(/* webpackChunkName: "admin-push-notification-list" */ '../views/Admin/PushNotificationList.vue'),
        meta: {
          title: 'Push Notification List',
        },
      },
      {
        path: 'send-push-notification',
        name: 'SendPushNotification',
        component: () => import(/* webpackChunkName: "admin-send-push-notification" */ '../views/Admin/SendPushNotification.vue'),
        meta: {
          title: 'Send Push Notification',
        },
      },
      {
        path: 'push-notification',
        name: 'MutatePushNotification',
        component: () => import(/* webpackChunkName: "admin-mutate-push-notification" */ '../views/Admin/MutatePushNotification.vue'),
        meta: {
          title: 'Add/Edit Push Notification',
        },
      },
      {
        path: 'settings',
        name: 'Settings',
        component: () => import(/* webpackChunkName: "admin-settings" */ '../views/Admin/MutateSettings.vue'),
        meta: {
          title: 'Settings',
        },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// ensuring user load from local storage attempted before routing
router.beforeEach(async (to, from, next) => {
  const prevRoutePath = from.fullPath;
  await useStore.app.init(prevRoutePath);
  next();
});

router.beforeResolve((to, from, next) => {
  const { user } = useStore.auth;

  if (!to.meta || !to.meta.title) {
    document.title = CONFIG.appName;
  } else {
    document.title = to.meta.title.toString();
  }

  // Redirect unauthenticated users to the login page
  if (!user) {
    if (to.fullPath !== CONSTANTS.ROUTES.GUEST_HOME) {
      next(CONSTANTS.ROUTES.GUEST_HOME);
      return;
    }
    next();
    return;
  }

  const shouldRedirectToMemberHome = to.meta?.isGuest || to.path === '/';

  if (shouldRedirectToMemberHome) {
    next(redirectUserAfterLogin(user).route);
    return;
  }

  // Allow authenticated users to proceed
  next();
});

export { routes };

export default router;
