<!--
 - Ankur Mursalin
 - encryptioner.github.io
 - December 08, 2021
 -->

<template>
  <div class="relative min-h-screen flex-col">
    <HeaderComponent
      v-if="showDefaultHeader"
    />

    <main>
      <BusNotification />

      <RouterView />
    </main>
    <FooterComponent
      v-if="showDefaultFooter"
    />
  </div>
</template>

<script lang="ts" setup>

import {
  computed,
  onBeforeMount,
  onBeforeUnmount,
} from 'vue';
import {
  useRoute,
} from 'vue-router';
import {
  viewportBreakpoint,
} from './helpers';
import BusNotification from '@/components/App/BusNotification.vue';
import FooterComponent from '@/components/App/FooterComponent.vue';
import HeaderComponent from '@/components/NavBar/HeaderComponent.vue';
import {
  useStore,
} from '@/store';

let resizedBreakpointEventHandler: (e: Event) => void;

const route = useRoute();
const appStore = useStore.app;

const showDefaultHeader = computed(() => {
  const { fullPath } = route;
  return !fullPath.startsWith('/auth')
    && !fullPath.startsWith('/admin');
});

const showDefaultFooter = computed(() => {
  const { fullPath } = route;
  return showDefaultHeader.value
    && !fullPath.startsWith('/user');
});

onBeforeMount(() => {
  resizedBreakpointEventHandler = (): void => {
    const resizedBreakpoint = viewportBreakpoint();
    if (resizedBreakpoint !== appStore.breakpoint) {
      appStore.breakpoint = resizedBreakpoint;
    }
  };
  window.addEventListener('resize', resizedBreakpointEventHandler);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', resizedBreakpointEventHandler);
});
</script>
