import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, resolveComponent as _resolveComponent, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "main-body",
  class: "leading-normal tracking-normal"
}
const _hoisted_2 = { class: "flex flex-wrap" }
const _hoisted_3 = {
  id: "main-content",
  class: "w-full"
}

import AdminNavBar from '@/components/AdminTemplate/AdminNavBar.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'AdminTemplate',
  setup(__props) {



return (_ctx: any,_cache: any) => {
  const _component_RouterView = _resolveComponent("RouterView")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(AdminNavBar, { class: "z-50" }),
        _createVNode(_component_RouterView, { class: "p-6" })
      ])
    ])
  ]))
}
}

})