import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { id: "auth" }
const _hoisted_2 = {
  class: "mx-auto px-2",
  style: { maxWidth: '540px', flexBasis: '540px' }
}
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "pt-7 text-center text-4xl font-bold text-[#143454]" }

import {
  getAppInfo,
} from 'utilities';
import {
  computed,
  ref,
} from 'vue';
import {
  useRoute, useRouter,
} from 'vue-router';
import AuthChoiceButtons from '@/components/Auth/AuthChoiceButtons.vue';
import {
  handleAxiosError,
  redirectUserAfterLogin,
} from '@/helpers';
import {
  useStore,
} from '@/store';
import {
  ILoginInfo,
  IUser,
} from '@/type';


export default /*@__PURE__*/_defineComponent({
  __name: 'AuthView',
  setup(__props) {


const route = useRoute();
const router = useRouter();
const { appName } = getAppInfo().CONFIG;

const authStore = useStore.auth;

const showEmailAuthChoice = ref(false);

const user = computed(() => authStore.user);
const showAuthChoice = computed(() => {
  const { path } = route;
  if (path === '/auth/login') {
    return true;
  }
  return false;
});

async function _redirectAccordingToRole(): Promise<void> {
  if (!user.value) {
    console.error('Cannot perform redirection while user is not set');
    return;
  }
  try {
    // redirect according to role
    const redirection = redirectUserAfterLogin(user.value as IUser);
    await router.push(redirection.route);
  } catch (e) {
    handleAxiosError(e);
  }
}

async function onAuthentication(loginInfo: ILoginInfo): Promise<void> {
  const { token } = loginInfo;
  await authStore.login(token);
  await _redirectAccordingToRole();
}


return (_ctx: any,_cache: any) => {
  const _component_RouterView = _resolveComponent("RouterView")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        src: require('@/assets/site-assets/logo.png'),
        class: "mx-auto mt-5 h-36 w-36 rounded",
        alt: "logo"
      }, null, 8, _hoisted_3),
      _createElementVNode("h1", _hoisted_4, _toDisplayString(_unref(appName)) + " Admin ", 1),
      _cache[1] || (_cache[1] = _createElementVNode("p", { class: "my-10 bg-red-50 py-2 pl-3 text-left text-xl font-bold" }, " Log in ", -1)),
      (showAuthChoice.value)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createVNode(AuthChoiceButtons, {
              showEmailAuthChoice: showEmailAuthChoice.value,
              "onClick:email": _cache[0] || (_cache[0] = ($event: any) => (showEmailAuthChoice.value = false))
            }, null, 8, ["showEmailAuthChoice"]),
            _createVNode(_component_RouterView, {
              formEnabled: !showEmailAuthChoice.value,
              onAuth: onAuthentication
            }, null, 8, ["formEnabled"])
          ], 64))
        : (_openBlock(), _createBlock(_component_RouterView, { key: 1 }))
    ])
  ]))
}
}

})