import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createBlock as _createBlock, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "min-w-screen sticky top-0" }
const _hoisted_2 = { class: "min-w-screen z-20 flex h-20 w-full items-center justify-between bg-gray-100 px-6 shadow-sm shadow-gray-500" }
const _hoisted_3 = { class: "relative" }
const _hoisted_4 = { tabindex: "0" }
const _hoisted_5 = {
  key: 1,
  tabindex: "0",
  class: "menu absolute -left-6 top-[-1.3rem] z-50 min-h-[101vh] w-60 border-1 bg-white p-2 pt-20 shadow-md shadow-black lg:min-h-screen lg:w-80"
}
const _hoisted_6 = { class: "relative" }
const _hoisted_7 = {
  key: 1,
  tabindex: "0",
  class: "menu absolute right-0 mt-2 w-max rounded-box bg-base-100 p-2 shadow-sm shadow-black"
}

import {
  ArrowRightStartOnRectangleIcon,
  Bars3Icon,
  BellIcon,
  CogIcon,
  UsersIcon,
  XMarkIcon,
} from '@heroicons/vue/24/outline';
import {
  computed,
  ref,
} from 'vue';
import {
  useRouter,
} from 'vue-router';
import ImageAvatar from '@/components/Helper/ImageAvatar.vue';
import {
  useStore,
} from '@/store';


export default /*@__PURE__*/_defineComponent({
  __name: 'AdminNavBar',
  setup(__props) {


const router = useRouter();
const authStore = useStore.auth;

const user = computed(() => authStore.user);

const isRightNavOpen = ref(false);
const isLeftNavOpen = ref(false);

async function logout(): Promise<void> {
  authStore.logout();
  await router.push('/');
}

// REFERENCE: https://github.com/Murked/vue-tailwind-admin/blob/master/src/components/Dashboard.vue


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("button", {
          class: "flex h-11 w-11 items-center justify-center rounded-full hover:bg-gray-300",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (isLeftNavOpen.value = !isLeftNavOpen.value))
        }, [
          _createElementVNode("label", _hoisted_4, [
            _createVNode(_unref(Bars3Icon), { class: "h-6 w-6 hover:cursor-pointer" })
          ])
        ]),
        (isLeftNavOpen.value)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              tabindex: "-1",
              class: "fixed inset-0 z-40 h-full w-full cursor-default bg-black opacity-70",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (isLeftNavOpen.value = false))
            }))
          : _createCommentVNode("", true),
        (isLeftNavOpen.value)
          ? (_openBlock(), _createElementBlock("ul", _hoisted_5, [
              _createElementVNode("button", {
                class: "absolute top-7 right-8 h-7 w-7 rounded-full text-[#ff0000] hover:bg-[#ff0000] hover:text-center hover:text-gray-100 hover:shadow-sm hover:shadow-gray-500 active:shadow-inner",
                onClick: _cache[2] || (_cache[2] = ($event: any) => (isLeftNavOpen.value = false))
              }, [
                _createVNode(_unref(XMarkIcon))
              ]),
              _cache[11] || (_cache[11] = _createElementVNode("span", null, [
                _createElementVNode("p", { class: "mb-1 pl-4 text-sm font-semibold lg:text-lg" }, " COLLECTION ")
              ], -1)),
              _createElementVNode("li", null, [
                _createElementVNode("button", {
                  type: "button",
                  class: _normalizeClass(["flex h-10 w-full cursor-pointer items-center rounded-lg pl-4 text-blue-400 hover:bg-gray-200", {'bg-blue-100': _unref(router).currentRoute.value.name === 'UserList'}]),
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (_unref(router).push('/admin/user-list')))
                }, [
                  _createVNode(_unref(UsersIcon), { class: "mr-2 h-6 w-6" }),
                  _cache[8] || (_cache[8] = _createElementVNode("span", { class: "text-gray-700" }, "Users", -1))
                ], 2),
                _createElementVNode("button", {
                  type: "button",
                  class: _normalizeClass(["flex h-10 w-full cursor-pointer items-center rounded-lg pl-4 text-blue-400 hover:bg-gray-200", {'bg-blue-100': _unref(router).currentRoute.value.name === 'PushNotificationList'}]),
                  onClick: _cache[4] || (_cache[4] = ($event: any) => (_unref(router).push('/admin/push-notification')))
                }, [
                  _createVNode(_unref(BellIcon), { class: "mr-2 h-6 w-6" }),
                  _cache[9] || (_cache[9] = _createElementVNode("span", { class: "text-gray-700" }, "Push Notification", -1))
                ], 2)
              ]),
              _cache[12] || (_cache[12] = _createElementVNode("span", { class: "mb-1 pl-4 text-sm font-semibold lg:pt-5 lg:text-lg" }, " MISC ", -1)),
              _createElementVNode("li", null, [
                _createElementVNode("button", {
                  type: "button",
                  class: _normalizeClass(["flex h-10 w-full cursor-pointer items-center rounded-lg pl-4 text-blue-400 hover:bg-gray-200", {'bg-blue-100': _unref(router).currentRoute.value.name === 'Settings'}]),
                  onClick: _cache[5] || (_cache[5] = ($event: any) => (_unref(router).push('/admin/settings')))
                }, [
                  _createVNode(_unref(CogIcon), { class: "mr-2 h-6 w-6" }),
                  _cache[10] || (_cache[10] = _createElementVNode("span", { class: "text-gray-700" }, "Settings", -1))
                ], 2)
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("button", {
          class: "btn-ghost h-[48px] w-[48px] rounded-full hover:bg-transparent",
          onClick: _cache[6] || (_cache[6] = ($event: any) => (isRightNavOpen.value = !isRightNavOpen.value))
        }, [
          (user.value)
            ? (_openBlock(), _createBlock(ImageAvatar, { key: 0 }))
            : _createCommentVNode("", true)
        ]),
        (isRightNavOpen.value)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              tabindex: "-1",
              class: "fixed inset-0 top-0 h-full w-full cursor-default bg-black opacity-20",
              onClick: _cache[7] || (_cache[7] = ($event: any) => (isRightNavOpen.value = false))
            }))
          : _createCommentVNode("", true),
        (isRightNavOpen.value)
          ? (_openBlock(), _createElementBlock("ul", _hoisted_7, [
              _createElementVNode("li", null, [
                _createElementVNode("button", {
                  class: "z-50 flex w-full justify-center hover:bg-red-400 hover:text-white active:bg-red-400",
                  onClick: logout
                }, [
                  _cache[13] || (_cache[13] = _createTextVNode(" Logout ")),
                  _createElementVNode("span", null, [
                    _createVNode(_unref(ArrowRightStartOnRectangleIcon), { class: "h-6 w-6" })
                  ])
                ])
              ])
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}
}

})