import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "mt-10 flex" }
const _hoisted_2 = {
  key: 0,
  class: "loading loading-spinner"
}

import axios, {
  AxiosError,
} from 'axios';
import type {
  LoginDto,
} from 'dto';
import {
  Form,
} from 'vee-validate';
import {
  ref,
} from 'vue';
import {
  object,
  string,
} from 'yup';
import CustomButton1 from '@/components/Helper/Button/CustomButton1.vue';
import InputHelper from '@/components/Helper/InputHelper.vue';
import {
  handleAxiosError,
} from '@/helpers';
import {
  ILoginInfo,
} from '@/type';

interface IFormSubmitValues {
  email: string;
  password: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'LoginView',
  props: {
  formEnabled: {
    type: Boolean,
    required: true,
  },
},
  emits: ["auth"],
  setup(__props, { emit: __emit }) {

const emits = __emit;



const schema = object().shape({
  email: string()
    .label('E-mail')
    .email()
    .required(),

  password: string()
    .label('Password')
    .min(3)
    .required(),
});

const isSubmitting = ref(false);

const serverError = ref('');
async function onSubmit(values: IFormSubmitValues): Promise<void> {
  try {
    if (serverError.value) {
      return;
    }

    isSubmitting.value = true;

    const {
      email,
      password,
    } = values;

    const loginPayload: LoginDto = {
      username: email,
      password,
    };

    const loginResponse = await axios.post<ILoginInfo>(
      '/auth/login',
      loginPayload,
    );

    emits('auth', loginResponse.data);
  } catch (e: any) {
    if (e.isAxiosError) {
      const data = (e as AxiosError).response?.data as any;
      serverError.value = data.message;
    } else {
      handleAxiosError(e);
    }
  } finally {
    isSubmitting.value = false;
  }
}


return (_ctx: any,_cache: any) => {
  return (__props.formEnabled)
    ? (_openBlock(), _createBlock(_unref(Form), {
        key: 0,
        class: "my-4 px-6",
        validationSchema: _unref(schema),
        onSubmit: onSubmit
      }, {
        default: _withCtx(({ isSubmitting: isValidatingForm }) => [
          _createVNode(InputHelper, {
            required: "",
            focused: "",
            name: "email",
            type: "email",
            label: "E-mail",
            placeholder: "Your email address",
            successMessage: "Got it!",
            onChange: _cache[0] || (_cache[0] = ($event: any) => (serverError.value = ''))
          }),
          _createVNode(InputHelper, {
            required: "",
            name: "password",
            type: "password",
            label: "Password",
            placeholder: "Your password",
            successMessage: "Got it!",
            errorText: serverError.value,
            onChange: _cache[1] || (_cache[1] = ($event: any) => (serverError.value = ''))
          }, null, 8, ["errorText"]),
          _createElementVNode("div", _hoisted_1, [
            _createVNode(CustomButton1, {
              content: "Log In",
              type: "submit",
              disabled: isSubmitting.value || isValidatingForm
            }, {
              default: _withCtx(() => [
                (isSubmitting.value)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_2))
                  : _createCommentVNode("", true)
              ]),
              _: 2
            }, 1032, ["disabled"])
          ])
        ]),
        _: 1
      }, 8, ["validationSchema"]))
    : _createCommentVNode("", true)
}
}

})