<!--
 - Ankur Mursalin
 - encryptioner.github.io
 - July 08, 2022
 -->

<template>
  <div class="min-w-screen sticky top-0">
    <div
      class="min-w-screen z-20 flex h-20 w-full items-center justify-between
        bg-gray-100 px-6 shadow-sm shadow-gray-500"
    >
      <!-- left navbar -->
      <div class="relative">
        <button
          class="flex h-11 w-11 items-center justify-center rounded-full hover:bg-gray-300"
          @click="isLeftNavOpen = !isLeftNavOpen"
        >
          <label
            tabindex="0"
          >
            <Bars3Icon
              class="h-6 w-6 hover:cursor-pointer"
            />
          </label>
        </button>
        <button
          v-if="isLeftNavOpen"
          tabindex="-1"
          class="fixed inset-0 z-40 h-full w-full cursor-default bg-black opacity-70"
          @click="isLeftNavOpen = false"
        />
        <ul
          v-if="isLeftNavOpen"
          tabindex="0"
          class="menu absolute -left-6 top-[-1.3rem] z-50 min-h-[101vh] w-60 border-1 bg-white p-2 pt-20
           shadow-md shadow-black lg:min-h-screen lg:w-80"
        >
          <button
            class="absolute top-7 right-8 h-7 w-7 rounded-full text-[#ff0000] hover:bg-[#ff0000]
             hover:text-center hover:text-gray-100 hover:shadow-sm hover:shadow-gray-500 active:shadow-inner"
            @click="isLeftNavOpen = false"
          >
            <XMarkIcon />
          </button>
          <span>
            <p class="mb-1 pl-4 text-sm font-semibold lg:text-lg">
              COLLECTION
            </p>
          </span>
          <li>
            <button
              type="button"
              class="flex h-10 w-full cursor-pointer items-center rounded-lg pl-4 text-blue-400 hover:bg-gray-200"
              :class="{'bg-blue-100': router.currentRoute.value.name === 'UserList'}"
              @click="router.push('/admin/user-list')"
            >
              <UsersIcon
                class="mr-2 h-6 w-6"
              />
              <span class="text-gray-700">Users</span>
            </button>

            <button
              type="button"
              class="flex h-10 w-full cursor-pointer items-center rounded-lg pl-4 text-blue-400 hover:bg-gray-200"
              :class="{'bg-blue-100': router.currentRoute.value.name === 'PushNotificationList'}"
              @click="router.push('/admin/push-notification')"
            >
              <BellIcon
                class="mr-2 h-6 w-6"
              />
              <span class="text-gray-700">Push Notification</span>
            </button>
          </li>
          <span class="mb-1 pl-4 text-sm font-semibold lg:pt-5 lg:text-lg">
            MISC
          </span>
          <li>
            <button
              type="button"
              class="flex h-10 w-full cursor-pointer items-center rounded-lg pl-4 text-blue-400 hover:bg-gray-200"
              :class="{'bg-blue-100': router.currentRoute.value.name === 'Settings'}"
              @click="router.push('/admin/settings')"
            >
              <CogIcon
                class="mr-2 h-6 w-6"
              />
              <span class="text-gray-700">Settings</span>
            </button>
          </li>
        </ul>
      </div>
      <!-- right navbar -->
      <!-- dropdown menu -->
      <div class="relative">
        <button
          class="btn-ghost h-[48px] w-[48px] rounded-full hover:bg-transparent"
          @click="isRightNavOpen = !isRightNavOpen"
        >
          <ImageAvatar
            v-if="user"
          />
        </button>
        <button
          v-if="isRightNavOpen"
          tabindex="-1"
          class="fixed inset-0 top-0 h-full w-full cursor-default bg-black opacity-20"
          @click="isRightNavOpen = false"
        />

        <ul
          v-if="isRightNavOpen"
          tabindex="0"
          class="menu absolute right-0 mt-2 w-max rounded-box bg-base-100 p-2 shadow-sm shadow-black"
        >
          <li>
            <button
              class="z-50 flex w-full justify-center hover:bg-red-400 hover:text-white active:bg-red-400"
              @click="logout"
            >
              Logout
              <span>
                <ArrowRightStartOnRectangleIcon class="h-6 w-6" />
              </span>
            </button>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>

import {
  ArrowRightStartOnRectangleIcon,
  Bars3Icon,
  BellIcon,
  CogIcon,
  UsersIcon,
  XMarkIcon,
} from '@heroicons/vue/24/outline';
import {
  computed,
  ref,
} from 'vue';
import {
  useRouter,
} from 'vue-router';
import ImageAvatar from '@/components/Helper/ImageAvatar.vue';
import {
  useStore,
} from '@/store';

const router = useRouter();
const authStore = useStore.auth;

const user = computed(() => authStore.user);

const isRightNavOpen = ref(false);
const isLeftNavOpen = ref(false);

async function logout(): Promise<void> {
  authStore.logout();
  await router.push('/');
}

// REFERENCE: https://github.com/Murked/vue-tailwind-admin/blob/master/src/components/Dashboard.vue

</script>
