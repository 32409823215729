import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = ["src"]

import {
  onBeforeMount,
  ref,
  watch,
} from 'vue';
import {
  getFileSrc,
} from '@/helpers';


export default /*@__PURE__*/_defineComponent({
  __name: 'ImageAvatar',
  props: {
  image: {
    type: String,
    default: '',
  },
  alternateText: {
    type: String,
    default: 'A',
  },
  size: {
    type: Number,
    default: 40,
  },
  bordered: {
    type: Boolean,
    default: false,
  },
  local: {
    type: Boolean,
    default: false,
  },
  isDarkBg: {
    type: Boolean,
    default: false,
  },
},
  emits: ["click"],
  setup(__props) {



const props = __props;

const finalImage = ref('');

function _setFinalImage(): void {
  finalImage.value = getFileSrc(props.image, props.local);
}

watch(() => props.image, () => {
  _setFinalImage();
});

onBeforeMount(() => {
  _setFinalImage();
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["avatar", { placeholder: !finalImage.value }]),
    tabindex: "0",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click'))),
    onKeypress: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('click')))
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["rounded-full transition-all", {
        'text-white ring-white ring-offset-white': __props.isDarkBg,
        'text-blue-1 ring-blue-1 ring-offset-blue-1': !__props.isDarkBg,
        'ring-1 ring-offset-1': __props.bordered || !finalImage.value,
        'bg-transparent': !finalImage.value,
      }]),
      style: _normalizeStyle({ width: `${__props.size}px`, height: `${__props.size}px` })
    }, [
      (finalImage.value)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            src: finalImage.value,
            alt: "profile-image"
          }, null, 8, _hoisted_1))
        : (_openBlock(), _createElementBlock("span", {
            key: 1,
            class: "transition-all",
            style: _normalizeStyle({ fontSize: `${__props.size / 2}px` })
          }, _toDisplayString(__props.alternateText), 5))
    ], 6)
  ], 34))
}
}

})