/*
 * Ankur Mursalin
 *
 * https://encryptioner.github.io/
 *
 * Created on Fri Jul 22 2022
 */

<template>
  <Form
    v-if="formEnabled"
    #default="{ isSubmitting: isValidatingForm }"
    class="my-4 px-6"
    :validationSchema="schema"
    @submit="onSubmit"
  >
    <InputHelper
      required
      focused
      name="email"
      type="email"
      label="E-mail"
      placeholder="Your email address"
      successMessage="Got it!"
      @change="serverError = ''"
    />

    <InputHelper
      required
      name="password"
      type="password"
      label="Password"
      placeholder="Your password"
      successMessage="Got it!"
      :errorText="serverError"
      @change="serverError = ''"
    />

    <div class="mt-10 flex">
      <CustomButton1
        content="Log In"
        type="submit"
        :disabled="isSubmitting || isValidatingForm"
      >
        <span
          v-if="isSubmitting"
          class="loading loading-spinner"
        />
      </CustomButton1>
    </div>
  </Form>
</template>

<script lang="ts" setup>
import axios, {
  AxiosError,
} from 'axios';
import type {
  LoginDto,
} from 'dto';
import {
  Form,
} from 'vee-validate';
import {
  ref,
} from 'vue';
import {
  object,
  string,
} from 'yup';
import CustomButton1 from '@/components/Helper/Button/CustomButton1.vue';
import InputHelper from '@/components/Helper/InputHelper.vue';
import {
  handleAxiosError,
} from '@/helpers';
import {
  ILoginInfo,
} from '@/type';

const emits = defineEmits<{
  (e: 'auth', value: ILoginInfo): void;
}>();

defineProps({
  formEnabled: {
    type: Boolean,
    required: true,
  },
});

interface IFormSubmitValues {
  email: string;
  password: string;
}

const schema = object().shape({
  email: string()
    .label('E-mail')
    .email()
    .required(),

  password: string()
    .label('Password')
    .min(3)
    .required(),
});

const isSubmitting = ref(false);

const serverError = ref('');
async function onSubmit(values: IFormSubmitValues): Promise<void> {
  try {
    if (serverError.value) {
      return;
    }

    isSubmitting.value = true;

    const {
      email,
      password,
    } = values;

    const loginPayload: LoginDto = {
      username: email,
      password,
    };

    const loginResponse = await axios.post<ILoginInfo>(
      '/auth/login',
      loginPayload,
    );

    emits('auth', loginResponse.data);
  } catch (e: any) {
    if (e.isAxiosError) {
      const data = (e as AxiosError).response?.data as any;
      serverError.value = data.message;
    } else {
      handleAxiosError(e);
    }
  } finally {
    isSubmitting.value = false;
  }
}

</script>
