/**
 * Ragib Un Nur
 * ragib@nerddevs.com
 * Created on Fri Jul 08 2022
 */
<template>
  <nav class="z-50">
    <div class="flex justify-between">
      <div class="flex">
        <RouterLink
          to="/"
        >
          <img
            :src="require('@/assets/site-assets/logo.png')"
            alt="logo"
            class="mx-auto mt-5 h-36 w-36 rounded"
          >
        </RouterLink>
      </div>
      <!-- burger-menu starts -->
      <div
        v-if="user"
        class="absolute left-4 z-20 mt-4 lg:hidden"
      >
        <button
          class="flex h-9 w-9 items-center justify-center rounded-full hover:bg-gray-400"
          @click="isLeftBurgerMenuOpen = !isLeftBurgerMenuOpen"
        >
          <label
            tabindex="0"
          >
            <Bars3Icon
              v-if="!isLeftBurgerMenuOpen"
              class="h-6 w-6 hover:cursor-pointer"
            />
          </label>
          <div
            v-if="isLeftBurgerMenuOpen"
            class="z-50 h-7 w-7 text-[#ff0000]"
          >
            <XMarkIcon />
          </div>
        </button>
        <button
          v-if="isLeftBurgerMenuOpen"
          tabindex="-1"
          class="fixed inset-0 top-0 z-40 h-full w-full cursor-default bg-gray-500 opacity-20"
          @click="isLeftBurgerMenuOpen = false"
        />
        <div
          v-if="isLeftBurgerMenuOpen"
          class="absolute top-auto left-0 z-50 w-48 rounded-lg border-gray-900 bg-white p-2 py-2 shadow-sm shadow-black"
        />
      </div>
      <!-- burger-menu-ends -->
      <RouterLink
        to="/"
      >
        <img
          :src="require('@/assets/site-assets/logo.png')"
          alt="logo"
          class="absolute"
          :class="{'left-6 top-4 w-11 lg:hidden': !user,
                   'left-[calc(50vw-calc(2.5rem/2))] top-2 mt-2 w-10 lg:hidden': user}"
        >
      </RouterLink>
      <div class="mr-4 flex">
        <MemberMenu
          v-if="user"
          :congested="true"
        />
      </div>
      <button
        v-if="!user"
        class="mt-3 mr-5 h-fit w-fit rounded-md bg-red-500 py-2 px-3 text-sm text-white
          shadow-sm shadow-purple-400 hover:bg-red-600 hover:shadow-none sm:mr-9 sm:text-base sm:font-semibold"
        @click="router.push('/auth/login')"
      >
        Sign in
      </button>
    </div>
  </nav>
</template>

<script lang="ts" setup>
import {
  Bars3Icon,
  XMarkIcon,
} from '@heroicons/vue/24/outline';
import {
  computed, ref,
} from 'vue';
import {
  useRouter,
} from 'vue-router';
import MemberMenu from '@/components/NavBar/MemberMenu.vue';
import {
  useStore,
} from '@/store';

const router = useRouter();

const authStore = useStore.auth;

const isLeftBurgerMenuOpen = ref(false);

const user = computed(() => authStore.user);

</script>
