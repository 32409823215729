/*
 * Ankur Mursalin
 *
 * https://encryptioner.github.io/
 *
 * Created on Thu Dec 14 2023
 */

<template>
  <div
    class="avatar"
    :class="{ placeholder: !finalImage }"
    tabindex="0"
    @click="$emit('click')"
    @keypress="$emit('click')"
  >
    <div
      class="rounded-full transition-all"
      :style="{ width: `${size}px`, height: `${size}px` }"
      :class="{
        'text-white ring-white ring-offset-white': isDarkBg,
        'text-blue-1 ring-blue-1 ring-offset-blue-1': !isDarkBg,
        'ring-1 ring-offset-1': bordered || !finalImage,
        'bg-transparent': !finalImage,
      }"
    >
      <img
        v-if="finalImage"
        :src="finalImage"
        alt="profile-image"
      >
      <span
        v-else
        class="transition-all"
        :style="{ fontSize: `${size / 2}px` }"
      >
        {{ alternateText }}
      </span>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {
  onBeforeMount,
  ref,
  watch,
} from 'vue';
import {
  getFileSrc,
} from '@/helpers';

defineEmits<{
  (e: 'click'): void;
}>();

const props = defineProps({
  image: {
    type: String,
    default: '',
  },
  alternateText: {
    type: String,
    default: 'A',
  },
  size: {
    type: Number,
    default: 40,
  },
  bordered: {
    type: Boolean,
    default: false,
  },
  local: {
    type: Boolean,
    default: false,
  },
  isDarkBg: {
    type: Boolean,
    default: false,
  },
});

const finalImage = ref('');

function _setFinalImage(): void {
  finalImage.value = getFileSrc(props.image, props.local);
}

watch(() => props.image, () => {
  _setFinalImage();
});

onBeforeMount(() => {
  _setFinalImage();
});

</script>
