import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["type", "disabled"]

import {
  PropType,
  ref,
} from 'vue';



const categories = ['category-1', 'category-2', 'category-3', 'category-4', 'category-5'] as const;
const types = ['button', 'submit', 'reset'] as const;



export default /*@__PURE__*/_defineComponent({
  __name: 'CustomButton1',
  props: {
  content: {
    type: String,
    default: '',
  },
  type: {
    type: String as PropType<typeof types[number]>,
    default: 'button',
    validator: (type: typeof types[number]) => types.includes(type),
  },
  largeText: {
    type: Boolean,
    default: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  // category-1 is the blue button, category-2 is light blue button, category-3 is the cancel button, category-4 is the red button
  category: {
    type: String as PropType<typeof categories[number]>,
    default: 'category-1',
    validator: (category: typeof categories[number]) => categories.includes(category),
  },
},
  setup(__props, { expose: __expose }) {



const buttonRef = ref<HTMLButtonElement | null>(null);

__expose({
  buttonRef,
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("button", {
    ref_key: "buttonRef",
    ref: buttonRef,
    class: _normalizeClass(["mx-auto flex h-13 w-full items-center justify-center gap-1 rounded-lg px-2 text-center font-medium leading-[48px] transition-all duration-300 hover:transition-all hover:duration-300",  {
      'category-1-attr': __props.category === 'category-1' && !__props.disabled,
      'category-2-attr': __props.category === 'category-2' && !__props.disabled,
      'category-3-attr': __props.category === 'category-3' && !__props.disabled,
      'category-4-attr': __props.category === 'category-4' && !__props.disabled,
      'category-5-attr': __props.category === 'category-5' && !__props.disabled,

      'shadow-md': __props.category !== 'category-3',

      'text-[14px] sm:text-[16px] lg:text-[20px]': __props.largeText,
      'text-[12px] sm:text-[14px] lg:text-[16px]': !__props.largeText,

      'active-animation': !__props.disabled,
      'bg-neutral-1/10 text-neutral-1/60': __props.disabled,
    } ]),
    type: __props.type,
    disabled: __props.disabled
  }, [
    _renderSlot(_ctx.$slots, "default"),
    (__props.content)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createTextVNode(_toDisplayString(__props.content), 1)
        ], 64))
      : _createCommentVNode("", true)
  ], 10, _hoisted_1))
}
}

})