/*
 * Ankur Mursalin
 *
 * https://encryptioner.github.io/
 *
 * Created on Fri Jul 22 2022
 */

<template>
  <div
    id="auth"
  >
    <div
      class="mx-auto px-2"
      :style="{ maxWidth: '540px', flexBasis: '540px' }"
    >
      <img
        :src="require('@/assets/site-assets/logo.png')"
        class="mx-auto mt-5 h-36 w-36 rounded"
        alt="logo"
      >

      <h1
        class="pt-7 text-center text-4xl font-bold text-[#143454]"
      >
        {{ appName }} Admin
      </h1>

      <p
        class="my-10 bg-red-50 py-2 pl-3 text-left text-xl font-bold"
      >
        Log in
      </p>

      <template
        v-if="showAuthChoice"
      >
        <AuthChoiceButtons
          :showEmailAuthChoice="showEmailAuthChoice"
          @click:email="showEmailAuthChoice = false"
        />

        <!-- for login using email -->
        <RouterView
          :formEnabled="!showEmailAuthChoice"
          @auth="onAuthentication"
        />
      </template>

      <!-- for other auth routes -->
      <RouterView
        v-else
      />
    </div>
  </div>
</template>

<script lang="ts" setup>

import {
  getAppInfo,
} from 'utilities';
import {
  computed,
  ref,
} from 'vue';
import {
  useRoute, useRouter,
} from 'vue-router';
import AuthChoiceButtons from '@/components/Auth/AuthChoiceButtons.vue';
import {
  handleAxiosError,
  redirectUserAfterLogin,
} from '@/helpers';
import {
  useStore,
} from '@/store';
import {
  ILoginInfo,
  IUser,
} from '@/type';

const route = useRoute();
const router = useRouter();
const { appName } = getAppInfo().CONFIG;

const authStore = useStore.auth;

const showEmailAuthChoice = ref(false);

const user = computed(() => authStore.user);
const showAuthChoice = computed(() => {
  const { path } = route;
  if (path === '/auth/login') {
    return true;
  }
  return false;
});

async function _redirectAccordingToRole(): Promise<void> {
  if (!user.value) {
    console.error('Cannot perform redirection while user is not set');
    return;
  }
  try {
    // redirect according to role
    const redirection = redirectUserAfterLogin(user.value as IUser);
    await router.push(redirection.route);
  } catch (e) {
    handleAxiosError(e);
  }
}

async function onAuthentication(loginInfo: ILoginInfo): Promise<void> {
  const { token } = loginInfo;
  await authStore.login(token);
  await _redirectAccordingToRole();
}

</script>
