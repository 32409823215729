<template>
  <footer
    class="absolute bottom-0 z-10 border-t-1 border-white"
  >
    <div class="w-screen bg-red-500 p-3 py-4 lg:flex lg:items-center lg:justify-between">
      <div
        class="flex items-center justify-center"
      />
    </div>
    <p class="p-4 text-center text-sm sm:text-base">
      Copyright © {{ currentYear }} {{ CONFIG.appName }}, all rights reserved
    </p>
  </footer>
</template>

<script lang="ts" setup>

import {
  CONFIG,
} from '@/helpers';

const currentYear = new Date().getFullYear();

</script>
