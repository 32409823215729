import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import {
  useRouter,
} from 'vue-router';

export interface IHeaderTextLink {
  name: string;
  route?: string;
  url?: string;
  event?: string;
  userOnly?: boolean;
  hidden?: boolean;
  isMobile?: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'HeaderTextLinkButton',
  props: {
  name: {
    type: String,
    required: true,
  },
  route: {
    type: String,
    required: false,
    default: '',
  },
  url: {
    type: String,
    required: false,
    default: '',
  },
  block: {
    type: Boolean,
    required: false,
  },
  event: {
    type: String,
    default: '',
  },
},
  setup(__props) {



const router = useRouter();

return (_ctx: any,_cache: any) => {
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_openBlock(), _createElementBlock("button", {
    class: "p-0 text-left",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit( __props.event || 'click')))
  }, [
    _createVNode(_component_RouterLink, {
      class: _normalizeClass(["w-full rounded-lg p-3 font-normal hover:bg-red-400 hover:text-white hover:no-underline", {'bg-red-200 text-red-500 font-bold w-full': __props.name === _unref(router).currentRoute.value.name}]),
      target: __props.url ? '_blank' : undefined,
      href: __props.url,
      to: __props.route
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(__props.name), 1)
      ]),
      _: 1
    }, 8, ["target", "href", "to", "class"])
  ]))
}
}

})