import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, normalizeClass as _normalizeClass, withModifiers as _withModifiers, Fragment as _Fragment, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "flex justify-between" }
const _hoisted_2 = ["for"]
const _hoisted_3 = {
  key: 0,
  class: "text-red-500"
}
const _hoisted_4 = { class: "flex" }
const _hoisted_5 = {
  key: 0,
  class: "w-19 z-1 my-auto ml-px flex h-14 rounded-l-md bg-slate-200"
}
const _hoisted_6 = {
  key: 0,
  class: "m-auto"
}
const _hoisted_7 = ["id", "name", "type", "value", "placeholder", "readonly", "disabled", "step"]
const _hoisted_8 = { class: "help-message absolute p-1 text-sm" }

import {
  EyeIcon,
  EyeSlashIcon,
} from '@heroicons/vue/24/outline';
import {
  PhPencilSlash,
} from '@phosphor-icons/vue';
import {
  useField,
} from 'vee-validate';
import {
  PropType,
  computed,
  onMounted,
  ref,
  toRef,
  watch,
} from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'InputHelper',
  props: {
  name: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    required: true,
  },
  step: {
    type: [Number, String],
    default: 1,
  },
  required: {
    type: Boolean,
    default: false,
  },
  focused: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  type: {
    type: String as PropType<'text' | 'password' | 'number' | 'email' | 'url' | 'tel'>,
    default: 'text',
    validator: (type: string) => type === 'text'
      || type === 'password' || type === 'number'
      || type === 'email' || type === 'url'
      || type === 'tel',
  },
  themeNo: {
    type: Number as PropType<1 | 2>,
    default: 2,
    validator: (type: number) => type === 1 || type === 2,
  },
  value: {
    type: String,
    default: '',
  },
  successMessage: {
    type: String,
    default: '',
  },
  placeholder: {
    type: String,
    default: '',
  },
  errorText: {
    type: String,
    default: '',
  },
  flag: {
    type: String,
    default: '',
  },
},
  emits: ["input", "blur", "change"],
  setup(__props, { emit: __emit }) {

const emits = __emit;

// TODO: add focused props to focus first input in form
const props = __props;

const isPasswordHidden = ref(true);
const isPasswordType = computed(() => props.type === 'password');

const computedType = computed(() => {
  if (isPasswordType.value && !isPasswordHidden.value) {
    return 'text';
  }
  return props.type;
});

const name = toRef(props, 'name');

// we don't provide any rules here because we are using form-level validation
// https://vee-validate.logaretm.com/v4/guide/validation#form-level-validation
const {
  value: inputValue,
  resetField,
  setState,
  errorMessage,
  handleBlur,
  handleChange,
  meta,
} = useField(name, undefined, {
  initialValue: props.value,
});

watch(() => props.value, () => {
  resetField({
    value: props.value,
  });
});

const inputEl = ref<HTMLInputElement | null>(null);

onMounted(() => {
  if (props.focused && inputEl.value) {
    inputEl.value.focus();
  }
});

function onKeyPress(e: KeyboardEvent): void {
  const isNumeric = e.key === '0' || parseInt(e.key, 10);

  if (props.type === 'tel' && !isNumeric) {
    e.preventDefault();
  }
}

function onInput(e): void {
  emits('input', inputValue.value);
  handleChange(e);
}

function onBlur(e): void {
  setState({
    value: e.target.value.trim(),
  });

  emits('blur', inputValue.value);
  handleBlur(e);
}

function onChange(): void {
  emits('change', inputValue.value);
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["relative pb-7", {
      [__props.themeNo === 1 ? 'has-error-1' : 'has-error-2']: !!_unref(errorMessage) || __props.errorText,
      [__props.themeNo === 1 ? 'success-1' : 'success-2']: _unref(meta).valid && !__props.errorText,
    }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("label", {
        class: "label block pt-0",
        for: name.value
      }, [
        _createTextVNode(_toDisplayString(__props.label) + " ", 1),
        (__props.required)
          ? (_openBlock(), _createElementBlock("span", _hoisted_3, "*"))
          : _createCommentVNode("", true)
      ], 8, _hoisted_2),
      (__props.disabled)
        ? (_openBlock(), _createBlock(_unref(PhPencilSlash), {
            key: 0,
            class: "mr-2"
          }))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_4, [
      (__props.flag)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            (__props.flag === 'BD')
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, _cache[2] || (_cache[2] = [
                  _createElementVNode("span", null, "🇧🇩 +88", -1)
                ])))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("input", {
        id: name.value,
        ref_key: "inputEl",
        ref: inputEl,
        name: name.value,
        type: computedType.value,
        value: _unref(inputValue),
        placeholder: __props.placeholder,
        readonly: __props.disabled,
        disabled: __props.disabled,
        step: __props.step,
        class: _normalizeClass([
          __props.themeNo === 1 ? 'input-1' : 'input-2',
          isPasswordType.value ? 'pr-10' : 'pr-3',
          { 'pl-22 ml-[-78px]': __props.flag },
          { 'pointer-events-none': __props.disabled },
        ]),
        onChange: onChange,
        onKeypress: onKeyPress,
        onInput: onInput,
        onBlur: onBlur
      }, null, 42, _hoisted_7),
      (isPasswordType.value)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            (isPasswordHidden.value)
              ? (_openBlock(), _createBlock(_unref(EyeSlashIcon), {
                  key: 0,
                  class: "size-6 -ml-8 mt-4 cursor-pointer",
                  onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (isPasswordHidden.value = !isPasswordHidden.value), ["prevent"]))
                }))
              : (_openBlock(), _createBlock(_unref(EyeIcon), {
                  key: 1,
                  class: "size-6 -ml-8 mt-4 cursor-pointer",
                  onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (isPasswordHidden.value = !isPasswordHidden.value), ["prevent"]))
                }))
          ], 64))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("p", _hoisted_8, [
      (_unref(errorMessage) || __props.errorText)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createTextVNode(_toDisplayString(_unref(errorMessage) || __props.errorText), 1)
          ], 64))
        : (_unref(meta).valid)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              _createTextVNode(_toDisplayString(__props.successMessage), 1)
            ], 64))
          : _createCommentVNode("", true)
    ]),
    _renderSlot(_ctx.$slots, "default")
  ], 2))
}
}

})