import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "mx-auto flex flex-col px-6" }

import {
  EnvelopeIcon,
} from '@heroicons/vue/24/outline';


export default /*@__PURE__*/_defineComponent({
  __name: 'AuthChoiceButtons',
  props: {
  showEmailAuthChoice: {
    type: Boolean,
    required: true,
  },
},
  emits: ["click:email"],
  setup(__props) {







return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (__props.showEmailAuthChoice)
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          class: "flex items-center border-2 bg-white",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click:email')))
        }, [
          _createVNode(_unref(EnvelopeIcon), { class: "ml-1 h-6 w-6" }),
          _cache[1] || (_cache[1] = _createElementVNode("div", { class: "auth-text" }, " Continue with Email ", -1))
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})